import PropTypes from 'prop-types';
import useAiSummaryReportsForFocusGroup from 'api-new/stim-based/useAiSummaryReportsForFocusGroup';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import React, { useEffect, useRef, useState } from 'react';
import NoReportsPlaceholder from './NoReportsPlaceholder';
import { Box, Button, GlobalStyles, Stack, Typography } from '@mui/material';
import AISummaryReport from './AISummaryReport';
import AiSystemInstructionType from 'enums/AiSystemInstructionType';
import { useReactToPrint } from 'react-to-print';
import { DateTime, Interval } from 'luxon';
import useBasicActivity from 'api-new/useBasicActivity';
import { DeleteForever, Print, RestartAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useFocusGroupSchedule from 'api-new/useFocusGroupSchedule';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import { useLocalStorage } from '@uidotdev/usehooks';
import { REPORT_PROCESSING_WINDOW_MINUTES } from 'Config';

const byReportType = (a, b) => {
    if (a.reportType === AiSystemInstructionType.SESSION_KEY_TAKEAWAYS) {
        return -1;
    }

    if (a.reportType === AiSystemInstructionType.SESSION_SUMMARY) {
        return 1;
    }

    return 0;
};

const getOverallReportStatus = (aiSummaryReports) => {
    if (!aiSummaryReports) {
        return AiSummaryReportStatus.PROCESSING;
    }

    const successfulReports = aiSummaryReports.filter(report => report.reportStatus === AiSummaryReportStatus.SUCCESS);
    const allReportsSuccessful = (successfulReports.length === aiSummaryReports.length) && aiSummaryReports.length > 0;

    if (aiSummaryReports.length === 0) {
        return AiSummaryReportStatus.NONE;
    } else if (aiSummaryReports.find(report => report.reportStatus === AiSummaryReportStatus.ERROR)) {
        return AiSummaryReportStatus.ERROR;
    } else if (aiSummaryReports.find(report => report.reportStatus === AiSummaryReportStatus.PROCESSING)) {
        return AiSummaryReportStatus.PROCESSING;
    } else if (allReportsSuccessful) {
        return AiSummaryReportStatus.SUCCESS;
    } else {
        return AiSummaryReportStatus.ERROR;
    }
};

const FocusGroupAIReports = ({ focusGroupId }) => {
    const printRef = useRef();
    const { t } = useTranslation();

    const regenerateReportsFeatureIsEnabled = useFeatureIsOn('regenerate-ai-reports');
    const deleteReportsFeatureIsEnabled = useFeatureIsOn('delete-ai-reports');

    // Used to temporarily force reports to poll for new data
    const [forcePollReports, setForcePollReports] = useState(false);

    const { data: activityData, isLoading: isLoadingActivityData } = useBasicActivity(focusGroupId);
    const { data: activitySchedule, isLoading: isLoadingActivitySchedule } = useFocusGroupSchedule(focusGroupId);
    const { data: aiSummaryReports, isLoading: isLoadingReports, refetch: fetchAiSummaryReports } = useAiSummaryReportsForFocusGroup(focusGroupId, forcePollReports);

    const [reportRequestedDate, setReportRequestedDate] = useLocalStorage(`report-requested-date-${focusGroupId}`, activitySchedule?.actualEndTime);

    const { mutate: generateReports, isLoading: isGeneratingReports } = useMutation(() => {
        return vlqrApi.post(`focusgroups/${focusGroupId}/generateAiSummaryReports`);
    }, {
        onSuccess: (data, variables, context) => {
            fetchAiSummaryReports();
            setReportRequestedDate(DateTime.now().toISO());
        },
        onError: handleError
    });

    const { mutate: deleteReport, isLoading: isDeletingReport } = useMutation(() => {
        return vlqrApi.delete(`focusGroups/${focusGroupId}/aiSummaryReports`);
    }, {
        onError: handleError,
        onSuccess: () => {
            fetchAiSummaryReports();
            setReportRequestedDate(null);
        }
    });

    useEffect(() => {
        if (reportRequestedDate && aiSummaryReports?.length === 0) {
            const reportRequestedDateTime = DateTime.fromISO(reportRequestedDate);
            const minutesSinceReportRequested = Interval.fromDateTimes(reportRequestedDateTime, DateTime.now()).toDuration('minutes').minutes;
            const isNewRequest = minutesSinceReportRequested < REPORT_PROCESSING_WINDOW_MINUTES;

            setForcePollReports(isNewRequest);
        } else {
            setForcePollReports(false);
        }

        return () => {
            setForcePollReports(false);
        };
    }, [reportRequestedDate, aiSummaryReports]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    if (isLoadingActivityData || isLoadingActivitySchedule || isLoadingReports) {
        return <LoadingPlaceholder />;
    }

    const overallReportStatus = forcePollReports
        ? AiSummaryReportStatus.PROCESSING
        : getOverallReportStatus(aiSummaryReports);

    if (overallReportStatus !== AiSummaryReportStatus.SUCCESS) {
        return (
            <Stack gap={4} sx={{ pt: 3, pb: 3 }}>
                <NoReportsPlaceholder
                    focusGroupId={focusGroupId}
                    overallReportStatus={overallReportStatus}
                    onGenerateReportsRequestSuccess={() => {
                        fetchAiSummaryReports();
                        setReportRequestedDate(DateTime.now().toISO());
                    }}
                />
            </Stack>
        );
    }

    return (
        <Stack
            ref={printRef}
            gap={2}
            sx={{
                px: 2, pt: 4,
                '@media print': { padding: 0 }
            }}
        >
            <GlobalStyles styles={{
                // Set default margins for print view
                '@page': {
                    margin: '20mm 15mm 20mm 15mm !important;'
                }
            }}/>

            <Box>
                <Stack direction={'row'} alignItems={'flex-start'}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h5">
                            { activityData.description }
                        </Typography>

                        { activitySchedule?.actualStartTime &&
                            <Typography variant="subtitle1" gutterBottom>
                                { DateTime.fromISO(activitySchedule.actualStartTime).toLocaleString(DateTime.DATETIME_FULL) }
                            </Typography>
                        }
                    </Box>

                    <Stack direction="row" useFlexGap gap={1}>
                        { deleteReportsFeatureIsEnabled &&
                            <LoadingButton
                                loading={isDeletingReport}
                                loadingPosition="start"
                                startIcon={<DeleteForever />}
                                variant="outlined"
                                color="error"
                                onClick={() => deleteReport()}
                            >
                                { t('common.buttons.delete') }
                            </LoadingButton>
                        }

                        { regenerateReportsFeatureIsEnabled &&
                            <LoadingButton
                                loading={isGeneratingReports}
                                loadingPosition="start"
                                startIcon={<RestartAlt />}
                                variant="outlined"
                                color="newPrimary"
                                onClick={() => generateReports()}
                            >
                                { t('aiSummaryReport.regenerate') }
                            </LoadingButton>
                        }

                        <Button
                            startIcon={<Print />}
                            variant="outlined"
                            color="newPrimary"
                            onClick={() => handlePrint()}
                            sx={{ '@media print': { display: 'none' } }}
                        >
                            { t('common.buttons.print') }
                        </Button>
                    </Stack>
                </Stack>
            </Box>

            { aiSummaryReports.toSorted(byReportType).map(report => (
                <AISummaryReport
                    key={report.id}
                    aiSummaryReportId={report.id}
                    reportType={report.reportType}
                    onDeleted={fetchAiSummaryReports}
                    onReportRegenerated={fetchAiSummaryReports}
                />
            )) }
        </Stack>
    );
};

FocusGroupAIReports.propTypes = {
    focusGroupId: PropTypes.string
};

export default FocusGroupAIReports;
