import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getAiSummaryReportRelatedReports = async (aiSummaryReportId) => {
    if (!aiSummaryReportId) {
        return [];
    }

    const { data } = await vlqrApi.get(`aiSummaryReports/${aiSummaryReportId}/relatedReports`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReportRelatedReports = (aiSummaryReportId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReportRelatedReports', aiSummaryReportId],
        () => getAiSummaryReportRelatedReports(aiSummaryReportId)
    );

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReportRelatedReports;
