import { Box, Divider, List, Stack, TablePagination, Typography } from '@mui/material';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import SessionSummaryReportsListItem from './SessionSummaryReportsListItem';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import useAiSummaryReportRelatedReports from 'api-new/stim-based/useAiSummaryReportRelatedReports';
import useAiSummaryReport from 'api-new/stim-based/useAiSummaryReport';
import { getOrderedFocusGroups } from '../util';
import { useTranslation } from 'react-i18next';

const SessionSummaryReportsList = ({ projectId, projectReportId, focusGroups, summaryReports }) => {
    const { t } = useTranslation();
    const { data: projectReport, isLoading: isLoadingProjectReport } = useAiSummaryReport(projectReportId);
    const { data: projectReportRelatedReports, isLoading: isLoadingRelatedReports, refetch: fetchRelatedReports } = useAiSummaryReportRelatedReports(projectReportId);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const orderedFocusGroups = useMemo(() => getOrderedFocusGroups(focusGroups, summaryReports), [focusGroups, summaryReports]);
    const projectReportContent = projectReport?.content;
    const projectReportExists = projectReport?.reportStatus === AiSummaryReportStatus.SUCCESS;

    // Keep related reports up to date when projectReport changes or the report content changes.
    // Note that related reports are only populated on the backend when the report content is
    // generated successfully.
    useEffect(() => {
        fetchRelatedReports();
    }, [projectReportId, projectReportContent, fetchRelatedReports]);

    if (!focusGroups || !summaryReports || isLoadingProjectReport || isLoadingRelatedReports) {
        return <LoadingPlaceholder />;
    }

    const relatedReportIds = projectReportRelatedReports?.map(x => x.id) ?? [];

    if (focusGroups?.length === 0) {
        return (
            <Box sx={{ backgroundColor: 'grey.10', padding: 2, borderRadius: 1 }}>
                <Typography variant="body1">
                    { t('sessionSummaryReportsList.noSessionsFound') }
                </Typography>
            </Box>
        );
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    // Get the current 'page' of list items.
    const pageStart = currentPage * rowsPerPage;
    const pageEnd = (currentPage * rowsPerPage) + rowsPerPage;
    const currentPageItems = orderedFocusGroups?.slice(pageStart, pageEnd).map(focusGroup => {
        const summaryReport = summaryReports?.find(summaryReport => summaryReport.focusGroupId === focusGroup.id);
        const generatedAfterReport = summaryReport && !relatedReportIds.includes(summaryReport.id);

        return {
            key: focusGroup.id,
            listItemProps: {
                focusGroupId: focusGroup.id,
                focusGroupTitle: focusGroup.internalTitle,
                focusGroupStatus: focusGroup.status,
                focusGroupScheduledStartTime: focusGroup.scheduledStartDateTime,
                focusGroupActualEndTime: focusGroup.actualEndDateTime,
                summaryReportId: summaryReport?.id,
                summaryReportStatus: summaryReport?.reportStatus,
                summaryReportModifiedDate: summaryReport?.modifiedOn,
                warnGeneratedAfterReport: projectReportExists && generatedAfterReport
            }
        };
    });

    return (
        <Stack sx={{ border: 'thin solid black', borderColor: 'grey.9', borderRadius: 1 }}>
            <List disablePadding>
                { currentPageItems.map(item => (
                    <React.Fragment key={`SessionSummaryReportsListItem-${item.key}`}>
                        <SessionSummaryReportsListItem { ...item.listItemProps } />
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
            <TablePagination
                component="div"
                count={orderedFocusGroups?.length ?? 0}
                page={currentPage}
                onPageChange={(event, newPage) => setCurrentPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Stack>
    );
};

SessionSummaryReportsList.propTypes = {
    focusGroups: PropTypes.array,
    projectId: PropTypes.string.isRequired,
    projectReportId: PropTypes.string,
    summaryReports: PropTypes.array
};

export default SessionSummaryReportsList;
