import React from 'react';
import { ChevronRight, ScienceOutlined, SupportAgent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import AiReportsImage from './undraw_done_checking_re_6vyx.svg';
import ActivityResultsImage from './undraw_pie_chart_re_bgs8.svg';
import AreaTile from './AreaTile';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProjectReportsHomepage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const projectActivityResultsFeatureEnabled = useFeatureIsOn('project-reports-activity-results');
    const aiReportsFeatureEnabled = useFeatureIsOn('ai-session-summaries');

    return (
        <Stack gap={4} sx={{ padding: 4 }} flexDirection="row">
            <AreaTile
                imageAlt={t('reportsHomepage.aiSummaryReports.imageAltText')}
                imageSrc={AiReportsImage}
                title={t('reportsHomepage.aiSummaryReports.title')}
                description={t('reportsHomepage.aiSummaryReports.description')}
                buttonIcon={aiReportsFeatureEnabled
                    ? <ChevronRight />
                    : <SupportAgent />
                }
                buttonText={aiReportsFeatureEnabled
                    ? t('common.viewReports')
                    : t('common.contactSupport')
                }
                disabled={!aiReportsFeatureEnabled}
                onClick={() => navigate('aiSummaryReports')}
            />

            <AreaTile
                imageAlt={t('reportsHomepage.activityResults.imageAltText')}
                imageSrc={ActivityResultsImage}
                title={t('reportsHomepage.activityResults.title')}
                description={t('reportsHomepage.activityResults.description')}
                buttonIcon={projectActivityResultsFeatureEnabled
                    ? <ChevronRight />
                    : <ScienceOutlined />
                }
                buttonText={projectActivityResultsFeatureEnabled
                    ? t('reportsHomepage.activityResults.exploreResults')
                    : t('common.comingSoon')
                }
                disabled={!projectActivityResultsFeatureEnabled}
                onClick={() => navigate('activityResults')}
            />
        </Stack>
    );
};

export default ProjectReportsHomepage;
