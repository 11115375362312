import { Stack, Typography } from '@mui/material';
import React from 'react';
import ReportsTabBreadcrumbs from '../ReportsTabBreadcrumbs';
import { useTranslation } from 'react-i18next';
import UnderConstructionImage from './undraw_under_construction_-46-pa.svg';

const ActivityResultsTab = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={4} useFlexGap sx={{ padding: 4 }}>
            <ReportsTabBreadcrumbs>
                <Typography sx={{ color: 'text.primary' }}>
                    { t('reportsHomepage.activityResults.title') }
                </Typography>
            </ReportsTabBreadcrumbs>

            <Stack alignItems={'center'} justifyContent={'center'} sx={{ backgroundColor: 'grey.10', padding: 4, py: 8, borderRadius: 2, gap: 6 }}>
                <img alt={'Under construction'} src={UnderConstructionImage} height="300" width="auto" />

                <Typography variant="h3">
                    Coming Soon!
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ActivityResultsTab;
