import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { Avatar, Button, Dialog, DialogActions, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import useAiSummaryReportFailures from 'api-new/stim-based/useAiSummaryReportFailures';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReportTaskErrorDetailsModal = ({ summaryReportId, isOpen, onClose }) => {
    const { t } = useTranslation();
    const { data: failures } = useAiSummaryReportFailures(summaryReportId);

    if (!failures) {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md">
            <List>
                { failures?.map(failure => (
                    <ListItem key={failure.id}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: 'error.main' }}>
                                <Close />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={failure.failureReason}
                            secondary={DateTime.fromISO(failure.createdOn).toLocaleString(DateTime.DATETIME_FULL)}
                        />
                    </ListItem>
                )) }
            </List>
            <DialogActions>
                <Button onClick={onClose}>
                    { t('common.buttons.close') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ReportTaskErrorDetailsModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    summaryReportId: PropTypes.string
};

export default ReportTaskErrorDetailsModal;
