import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import useAiSummaryReportTimespan from 'api-new/stim-based/useAiSummaryReportTimespan';
import { DateTime } from 'luxon';
import React from 'react';

const ProjectSummaryReportTimespan = ({ projectReportId }) => {
    const { data: projectReportTimespan, isLoading: isLoadingProjectReportTimespan } = useAiSummaryReportTimespan(projectReportId);

    if (isLoadingProjectReportTimespan) {
        return null;
    }

    const startDateString = DateTime.fromISO(projectReportTimespan?.startedOn).toLocaleString(DateTime.DATE_MED);
    const endDateString = DateTime.fromISO(projectReportTimespan?.endedOn).toLocaleString(DateTime.DATE_MED);

    return (
        <Typography variant="subtitle1">
            { startDateString } - {endDateString}
        </Typography>
    );
};

ProjectSummaryReportTimespan.propTypes = {
    projectReportId: PropTypes.string
};

export default ProjectSummaryReportTimespan;
