import PropTypes from 'prop-types';
import { Button, Stack, styled, Typography } from '@mui/material';
import React from 'react';

const VLAreaTile = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.grey[10],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    alignItems: 'center',
    gap: theme.spacing(4),
    width: '100%'
}));

const AreaTile = ({ imageSrc, imageAlt, title, description, buttonText, buttonIcon, disabled, onClick }) => {
    return (
        <VLAreaTile>
            <img alt={imageAlt} src={imageSrc} height="240" width="auto" />

            <Stack alignItems={'center'}>
                <Typography variant="h5" gutterBottom>
                    { title }
                </Typography>
                <Typography variant="body1" align='center'>
                    { description }
                </Typography>
            </Stack>

            <Button endIcon={buttonIcon} variant="outlined" size="large" onClick={onClick} disabled={disabled}>
                { buttonText }
            </Button>
        </VLAreaTile>
    );
};

AreaTile.propTypes = {
    buttonIcon: PropTypes.node,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    imageAlt: PropTypes.string,
    imageSrc: PropTypes.node,
    onClick: PropTypes.func,
    title: PropTypes.string
};

export default AreaTile;
