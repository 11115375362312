import PropTypes from 'prop-types';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import vlqrApi from 'utils/vlqrApi';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import useVoiceTranscriptionsForActivity from 'api-new/useVoiceTranscriptionsForActivity';
import { Trans, useTranslation } from 'react-i18next';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { handleError } from 'utils/portalApi';
import ValAvatar from 'components/ValAvatar/ValAvatar';
import AIReportGenerationErrorAlert from 'components/AIReportGenerationErrorAlert/AIReportGenerationErrorAlert';

const NoReportsPlaceholder = ({ focusGroupId, onGenerateReportsRequestSuccess, overallReportStatus }) => {
    const { t } = useTranslation();
    const [retryFailed, setRetryFailed] = useState(false);
    const isProcessingReports = overallReportStatus === AiSummaryReportStatus.PROCESSING;

    const { mutate: generateReports, isLoading: isGeneratingReports } = useMutation(() => {
        return vlqrApi.post(`focusgroups/${focusGroupId}/generateAiSummaryReports`);
    }, {
        onSuccess: () => {
            onGenerateReportsRequestSuccess?.();
        }, onError: (error) => {
            setRetryFailed(true);
            handleError(error);
        }
    });

    const { data: voiceTranscriptions, isLoading: isLoadingVoiceTranscriptions } = useVoiceTranscriptionsForActivity(focusGroupId);

    if (isLoadingVoiceTranscriptions) {
        return <LoadingPlaceholder />;
    }

    const isLackingVoiceTranscriptions = voiceTranscriptions.length === 0;
    const showGenerateReportsButton = !isLackingVoiceTranscriptions && [AiSummaryReportStatus.NONE, AiSummaryReportStatus.ERROR].includes(overallReportStatus);

    const missingVoiceTranscriptsAlert =
        <Alert severity='warning' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('aiReports.noVoiceTranscriptionsAvailable') }</AlertTitle>

            <Trans
                i18nKey="aiReports.noVoiceTranscriptionsExplanation"
                components={{ emailSupportLink: <Link color="warning.contrastText" href="mailto:support@visionslive.com" /> }}
            />
        </Alert>;

    const processingAlert =
        <Alert severity='info' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('session.aiReports.processingReports') }</AlertTitle>
            { t('session.aiReports.processingReportsExplanation') }
        </Alert>;

    // Tech-debt: Ideally we want to enable viewing error details here, but unsure how to go about
    // determining company role (i.e. 'isStaff') from current router structure.
    const errorGeneratingAlert = <AIReportGenerationErrorAlert />;

    const generateReportsButton =
        <LoadingButton
            loading={isGeneratingReports}
            loadingPosition="start"
            disabled={retryFailed}
            startIcon={<AutoAwesome />}
            variant="contained"
            color="newPrimary"
            size="large"
            onClick={() => generateReports()}
        >
            { t('aiReports.generateAISummary') }
        </LoadingButton>;

    return (
        <Stack
            alignItems='center'
            sx={{ backgroundColor: 'grey.10', px: 4, py: 8, borderRadius: 2 }}
            gap={3}
        >
            <ValAvatar isProcessing={isProcessingReports} />

            <Stack gap={1} alignItems='center' sx={{ width: '40em' }}>
                <Typography variant="h5" sx={{ color: 'grey.2' }}>
                    { t('aiReports.meetVal') }
                </Typography>

                <Typography variant="body1" align='center'>
                    { t('aiReports.meetValBlurb') }
                </Typography>
            </Stack>

            { isProcessingReports && processingAlert }
            { overallReportStatus === AiSummaryReportStatus.ERROR && errorGeneratingAlert }
            { isLackingVoiceTranscriptions && missingVoiceTranscriptsAlert }

            { showGenerateReportsButton && generateReportsButton }
        </Stack>
    );
};

NoReportsPlaceholder.propTypes = {
    focusGroupId: PropTypes.string,
    onGenerateReportsRequestSuccess: PropTypes.func,
    overallReportStatus: PropTypes.oneOf(Object.values(AiSummaryReportStatus))
};

export default NoReportsPlaceholder;
