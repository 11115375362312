import PropTypes from 'prop-types';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import vlqrApi from 'utils/vlqrApi';
import { useTranslation } from 'react-i18next';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { handleError } from 'utils/portalApi';
import ValAvatar from 'components/ValAvatar/ValAvatar';
import { useOutletContext } from 'react-router-dom';
import useCompany from 'api/useCompany';
import CompanyRole from 'enums/CompanyRole';
import AIReportGenerationErrorAlert from 'components/AIReportGenerationErrorAlert/AIReportGenerationErrorAlert';

const NoReportsPlaceholder = ({
    projectId,
    onGenerateReportRequestSuccess,
    projectReportStatus,
    aiSummaryReportId,
    warnBeforeGenerating,
    reportGenerationDisabledReason
}) => {
    const { t } = useTranslation();
    const isError = projectReportStatus === AiSummaryReportStatus.ERROR;
    const isProcessingReports = projectReportStatus === AiSummaryReportStatus.PROCESSING;
    const showGenerateReportButton = [AiSummaryReportStatus.NONE, AiSummaryReportStatus.ERROR].includes(projectReportStatus);
    const [isConfirmingReportGeneration, setIsConfirmingReportGeneration] = useState(false);

    const { companyId } = useOutletContext();
    const { data: selectedCompany } = useCompany(companyId);
    const companyRole = CompanyRole[selectedCompany?.role];
    const currentUserIsStaff = companyRole === CompanyRole.vlStaff;

    const { mutate: generateReport, isLoading: isGeneratingReport } = useMutation(() => {
        return vlqrApi.post(`projects/${projectId}/generateAiSummaryReport`);
    }, {
        onSuccess: () => {
            onGenerateReportRequestSuccess?.();
            setIsConfirmingReportGeneration(false);
        },
        onError: (error) => {
            handleError(error);
        }
    });

    const onClickGenerateReport = () => {
        if (warnBeforeGenerating) {
            setIsConfirmingReportGeneration(true);
        } else {
            generateReport();
        }
    };

    const processingAlert =
        <Alert severity='info' variant='filled' sx={{ maxWidth: '40em' }}>
            <AlertTitle>{ t('aiReports.processingReport') }</AlertTitle>
            { t('aiReports.project.processingReportsExplanation') }
        </Alert>;

    const generateReportButton =
        <Tooltip title={reportGenerationDisabledReason ?? ''} arrow>
            <div>
                <LoadingButton
                    loading={isGeneratingReport}
                    loadingPosition="start"
                    disabled={isGeneratingReport || Boolean(reportGenerationDisabledReason)}
                    startIcon={<AutoAwesome />}
                    variant="contained"
                    color="newPrimary"
                    size="large"
                    onClick={onClickGenerateReport}
                >
                    { t('aiReports.generateAISummary') }
                </LoadingButton>
            </div>
        </Tooltip>;

    return (
        <React.Fragment>
            <Stack
                alignItems='center'
                sx={{ backgroundColor: 'grey.10', px: 4, py: 8, borderRadius: 2 }}
                gap={3}
            >
                <ValAvatar isProcessing={isProcessingReports} />

                <Stack gap={1} alignItems='center' sx={{ width: '40em' }}>
                    <Typography variant="h5" sx={{ color: 'grey.2' }}>
                        { t('aiReports.meetVal') }
                    </Typography>

                    <Typography variant="body1" align='center'>
                        { t('aiReports.project.meetValBlurb') }
                    </Typography>
                </Stack>

                { isError &&
                    <AIReportGenerationErrorAlert
                        aiSummaryReportId={aiSummaryReportId}
                        enableViewDetails={currentUserIsStaff}
                        isProjectReport
                    />
                }

                { isProcessingReports && processingAlert }
                { showGenerateReportButton && generateReportButton }
            </Stack>

            <Dialog open={isConfirmingReportGeneration} onClose={() => setIsConfirmingReportGeneration(false)}>
                <DialogTitle>
                    { t('noReportsPlaceholder.missingSummaryReportsWarningTitle') }
                </DialogTitle>
                <DialogContent>
                    <Alert severity='warning' variant='filled'>
                        { t('noReportsPlaceholder.missingSummaryReportsWarningBlurb') }
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsConfirmingReportGeneration(false)}>
                        { t('common.cancel') }
                    </Button>
                    <LoadingButton
                        loading={isGeneratingReport}
                        loadingPosition="start"
                        disabled={isGeneratingReport}
                        startIcon={<AutoAwesome />}
                        variant="outlined"
                        color="warning"
                        onClick={generateReport}
                    >
                        { t('aiReports.generateAISummary') }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

NoReportsPlaceholder.propTypes = {
    aiSummaryReportId: PropTypes.string,
    onGenerateReportRequestSuccess: PropTypes.func,
    projectId: PropTypes.string,
    projectReportStatus: PropTypes.oneOf(Object.values(AiSummaryReportStatus)),
    warnBeforeGenerating: PropTypes.bool,
    reportGenerationDisabledReason: PropTypes.string
};

export default NoReportsPlaceholder;
