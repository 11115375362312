import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const IncompleteFocusGroupStatus = ({ focusGroupId, focusGroupScheduledStartTime }) => {
    const { t } = useTranslation();

    if (focusGroupScheduledStartTime) {
        const startDateHumanReadable = DateTime.fromISO(focusGroupScheduledStartTime).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);

        return (
            <Stack direction="row" gap={1}>
                <Typography variant="body1">
                    { t('incompleteFocusGroupStatus.sessionHasNotFinished') }
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Typography variant="body1">
                    { t('incompleteFocusGroupStatus.scheduled', { scheduledStartDate: startDateHumanReadable }) }
                </Typography>
            </Stack>
        );
    } else {
        return (
            <Typography variant="body1">
                { t('incompleteFocusGroupStatus.sessionHasNotFinished') }
            </Typography>
        );
    }
};

IncompleteFocusGroupStatus.propTypes = {
    focusGroupId: PropTypes.string,
    focusGroupScheduledStartTime: PropTypes.string
};

export default IncompleteFocusGroupStatus;
