import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getAiSummaryReportFailures = async (aiSummaryReportId) => {
    const { data } = await vlqrApi.get(`aiSummaryReports/${aiSummaryReportId}/failures`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReportFailures = (aiSummaryReportId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReportFailures', aiSummaryReportId],
        () => getAiSummaryReportFailures(aiSummaryReportId)
    );

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReportFailures;
