import PropTypes from 'prop-types';
import { Alert, AlertTitle, Box, Link } from '@mui/material';
import ReportTaskErrorDetailsModal from 'components/ReportTaskErrorDetailsModal/ReportTaskErrorDetailsModal';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const AIReportGenerationErrorAlert = ({
    aiSummaryReportId,
    enableViewDetails = false
}) => {
    const { t } = useTranslation();
    const [isViewingErrorDetails, setIsViewingErrorDetails] = useState(false);

    return (
        <React.Fragment>
            <Alert severity='error' variant='filled' sx={{ maxWidth: '40em' }}>
                <AlertTitle>{ t('aiReports.errorGeneratingReports') }</AlertTitle>

                <Trans
                    i18nKey={'aiReports.errorGeneratingReportsExplanation'}
                    components={{ emailSupportLink: <Link color="warning.contrastText" href="mailto:support@visionslive.com" /> }}
                />

                { enableViewDetails &&
                    <Box sx={{ marginTop: 1 }}>
                        <Link component="button" color="warning.contrastText" onClick={() => setIsViewingErrorDetails(true)} sx={{ cursor: 'pointer' }}>
                            { t('common.viewDetails') }
                        </Link>
                    </Box>
                }
            </Alert>

            <ReportTaskErrorDetailsModal
                summaryReportId={aiSummaryReportId}
                isOpen={isViewingErrorDetails}
                onClose={() => setIsViewingErrorDetails(false)}
            />
        </React.Fragment>
    );
};

AIReportGenerationErrorAlert.propTypes = {
    aiSummaryReportId: PropTypes.string,
    enableViewDetails: PropTypes.bool,
    isProjectReport: PropTypes.bool
};

export default AIReportGenerationErrorAlert;
