import PropTypes from 'prop-types';
import { ArticleOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, styled } from '@mui/material';
import AIReportsPanel from 'components/FocusGroupAIReports/FocusGroupAIReports';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const ViewReportAction = ({ focusGroupId }) => {
    const { t } = useTranslation();
    const [isViewingReport, setIsViewingReport] = useState(false);

    return (
        <React.Fragment>
            <StyledButton startIcon={<ArticleOutlined />} onClick={() => setIsViewingReport(true)}>
                { t('common.viewReport') }
            </StyledButton>

            <Dialog open={isViewingReport} onClose={() => setIsViewingReport(false)} maxWidth="md">
                <DialogContent>
                    <AIReportsPanel focusGroupId={focusGroupId} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsViewingReport(false)}>
                        { t('common.buttons.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

ViewReportAction.propTypes = {
    focusGroupId: PropTypes.string
};

export default ViewReportAction;
