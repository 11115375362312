import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

const ReportsTab = () => {
    const {
        company_guid: companyId,
        project_guid: projectId
    } = useParams();

    return (
        <Outlet context={{companyId, projectId}} />
    );
};

export default ReportsTab;
