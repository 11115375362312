import React, { useEffect } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    redirect
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

import { create } from 'jss';
import rtl from 'jss-rtl';

// State
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyCount } from './redux/actions';

import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';

// Theming (Material-UI)
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Views
import SelectCompany from './pages/SelectCompany/SelectCompany';
import AccountSettings from './components/user/AccountSettings';
import OfflineView from './components/offline/OfflineView';
import CompanyOverview from './pages/CompanyOverview/CompanyOverview';
import ProjectOverview from './pages/ProjectOverview/ProjectOverview';
import ActivityOverview from 'pages/ActivityOverview/ActivityOverview';
import AboutHome from './components/about/Home';
import Login from './pages/Login/Login';

// Utils
import portalApi, { handleError } from './utils/portalApi';
import SearchResults from './components/SearchResults/SearchResults';
import { ActivityRecordingViewerPage } from 'pages/ActivityRecordingViewer/ActivityRecordingViewer';
import VoiceTranscriptionEditorPage from 'pages/VoiceTranscriptionEditor/VoiceTranscriptionEditorPage';
import RankingPollReport from 'pages/RankingPollReport/RankingPollReport';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'utils/growthbookContext';
import ProjectsTab from 'pages/CompanyOverview/projects/ProjectsTab';
import { CompanySettingsIframe, CompanyUsageAndBillingIframe, CompanyUsersIframe } from 'pages/CompanyOverview/CompanyIFrames';
import ActivitiesTab from 'pages/ProjectOverview/ActivitiesTab';
import { ProjectSettingsIframe, ProjectUsersIframe } from 'pages/ProjectOverview/ProjectIFrames';
import MainPageLayout from 'components/shared/MainPageLayout';
import { TextAnalysisDashboardPage } from 'pages/TextAnalysisDashboard/TextAnalysisDashboardPage';
import AiReportsTab from 'pages/ProjectOverview/ReportsTab/AiReports/AiReportsTab';
import ActivityResultsTab from 'pages/ProjectOverview/ReportsTab/ActivityResults/ActivityResultsTab';
import ProjectReportsHomepage from 'pages/ProjectOverview/ReportsTab/ProjectReportsHomepage/ProjectReportsHomepage';
import ReportsTab from 'pages/ProjectOverview/ReportsTab/ReportsTab';

// Routing rules for all pages in the app
export const routerConfig = [
    { path: '/reports/rankingPollReport/:pollId', element: <RankingPollReport /> },
    { path: '/recordingViewer/:activity_guid', element: <ActivityRecordingViewerPage /> },
    { element: <MainPageLayout />, children: [
        { path: 'company/:company_guid/project/:project_guid/activity/:activity_guid/*?', element: <ActivityOverview />},
        { path: 'company/:company_guid/project/:project_guid/*', element: <ProjectOverview />, children: [
            { path: 'activities', element: <ActivitiesTab /> },
            { path: 'reports', element: <ReportsTab />, children: [
                { path: '', element: <ProjectReportsHomepage /> },
                { path: 'aiSummaryReports', element: <AiReportsTab /> },
                { path: 'activityResults', element: <ActivityResultsTab /> }
            ]},
            { path: 'users', element: <ProjectUsersIframe /> },
            { path: 'settings', element: <ProjectSettingsIframe /> },
            { index: true, loader: () => redirect('activities') }
        ]},
        { path: 'company/:company_guid/*', element: <CompanyOverview />, children: [
            { path: 'projects', element: <ProjectsTab /> },
            { path: 'users', element: <CompanyUsersIframe /> },
            { path: 'usage-and-billing', element: <CompanyUsageAndBillingIframe /> },
            { path: 'settings', element: <CompanySettingsIframe /> },
            { index: true, loader: () => redirect('projects') }
        ]},
        { path: 'selectCompany', element: <SelectCompany /> },
        { path: 'currentUser/accountSettings', element: <AccountSettings /> },
        { path: 'about', element: <AboutHome /> },
        { path: 'search', element: <SearchResults /> },
        { path: 'activity/:activity_guid/voiceTranscriptionEditor', element: <VoiceTranscriptionEditorPage /> },
        { path: 'activity/:activity_guid/textAnalysis', element: <TextAnalysisDashboardPage /> },
        { path: 'login', element: <Login /> },
        { index: true, loader: () => redirect('selectCompany') }
    ] }
];
const router = createBrowserRouter(routerConfig);

const App = () => {
    const dispatch = useDispatch();
    const isOffline = useSelector(state => state.UserInterfaceStateReducer.isOffline);

    const currentTheme = useSelector(state => state.UserInterfaceStateReducer.theme);
    const theme = currentTheme;

    useEffect(() => {
        growthbook.loadFeatures();
    }, []);

    // Prepare for rtl themes if necessary.
    const { i18n, t } = useTranslation();

    // Set Yup locale so that error messages are translated.
    setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: 'field_invalid',
            required: t('common.errors.thisFieldIsRequired')
        },
        // use functions to generate an error object that includes the value from the schema
        number: {
            min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
            max: ({ max }) => ({ key: 'field_too_big', values: { max } })
        }
    });

    theme.direction = i18n.dir();
    document.getElementsByTagName('body')[0].setAttribute('dir', i18n.dir());

    const jssPlugins = [...jssPreset().plugins];
    if (i18n.dir() === 'rtl') {
        jssPlugins.push(rtl());
    }
    const jss = create({ plugins: jssPlugins });

    useEffect(() => {
        portalApi
            .get('/api/companies')
            .then(response => {
                dispatch(setCompanyCount(response.data.length));
            })
            .catch(handleError);
    }, [dispatch]);

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <StylesProvider jss={jss}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        { isOffline &&  <OfflineView /> }
                        { !isOffline && <RouterProvider router={router} /> }
                    </ThemeProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </GrowthBookProvider>
    );
};


export default App;
