import PropTypes from 'prop-types';
import React from 'react';
import { FocusGroupSummaryReportStatus } from '../../util';
import ViewReportAction from './ViewReportAction';
import GenerateReportAction from './GenerateReportAction';

const SummaryReportListItemAction = ({ focusGroupId, summaryReportId, focusGroupSummaryReportStatus, onReportRequested }) => {
    switch (focusGroupSummaryReportStatus) {
        case FocusGroupSummaryReportStatus.NO_REPORT_TASKS:
            return <GenerateReportAction focusGroupId={focusGroupId} onGenerateReportRequestSuccess={onReportRequested} />;
        case FocusGroupSummaryReportStatus.SUMMARY_REPORT_GENERATED_SUCESSFULLY:
            return <ViewReportAction focusGroupId={focusGroupId} />;
        case FocusGroupSummaryReportStatus.ERRONEOUS_SUMMARY_REPORT:
            return <GenerateReportAction isRetry focusGroupId={focusGroupId} onGenerateReportRequestSuccess={onReportRequested} />;
        default:
            return null;
    }
};

SummaryReportListItemAction.propTypes = {
    focusGroupId: PropTypes.string,
    focusGroupSummaryReportStatus: PropTypes.oneOf(Object.values(FocusGroupSummaryReportStatus)),
    onReportRequested: PropTypes.func,
    summaryReportId: PropTypes.string
};

export default SummaryReportListItemAction;
