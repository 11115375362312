import PropTypes from 'prop-types';
import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Breadcrumbs, Divider, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReportsTabBreadcrumbs = ({ children }) => {
    const { t } = useTranslation();

    return (
        <Stack gap={2}>
            <Breadcrumbs>
                <Link
                    to=".."
                    component={RouterLink}
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}
                >
                    <ArrowBack sx={{ mr: 1 }} fontSize="inherit" />
                    { t('common.reports') }
                </Link>

                { children }
            </Breadcrumbs>

            <Divider />
        </Stack>
    );
};

ReportsTabBreadcrumbs.propTypes = {
    children: PropTypes.node
};

export default ReportsTabBreadcrumbs;
