import PropTypes from 'prop-types';
import React from 'react';
import IncompleteFocusGroupStatus from './IncompleteFocusGroupStatus';
import ErroneousReportsStatus from './ReportTasksErrorStatus';
import { Typography } from '@mui/material';
import { FocusGroupSummaryReportStatus } from '../../util';
import { useTranslation } from 'react-i18next';

const SummaryReportStatusText = ({ focusGroupId, focusGroupScheduledStartTime, summaryReportId, focusGroupSummaryReportStatus }) => {
    const { t } = useTranslation();

    switch (focusGroupSummaryReportStatus) {
        case FocusGroupSummaryReportStatus.FOCUS_GROUP_INCOMPLETE:
            return (
                <IncompleteFocusGroupStatus
                    focusGroupId={focusGroupId}
                    focusGroupScheduledStartTime={focusGroupScheduledStartTime}
                />
            );
        case FocusGroupSummaryReportStatus.NO_REPORT_TASKS:
            return (
                <Typography variant="body1">
                    { t('summaryReportStatusText.notAutoGenerated') }
                </Typography>
            );
        case FocusGroupSummaryReportStatus.ERRONEOUS_SUMMARY_REPORT:
            return (
                <ErroneousReportsStatus summaryReportId={summaryReportId} />
            );
        case FocusGroupSummaryReportStatus.PROCESSING_SUMMARY_REPORT:
            return (
                <Typography variant="body1" color="info.dark">
                    { t('common.generatingReport') }
                </Typography>
            );
        case FocusGroupSummaryReportStatus.SUMMARY_REPORT_GENERATED_SUCESSFULLY:
            return (
                <Typography variant="body1" color="success.dark">
                    { t('common.reportGeneratedSuccessfully') }
                </Typography>
            );
        default:
            return <Typography variant="body1">Unknown</Typography>;
    }
};

SummaryReportStatusText.propTypes = {
    focusGroupId: PropTypes.string,
    focusGroupScheduledStartTime: PropTypes.string,
    focusGroupSummaryReportStatus: PropTypes.oneOf(Object.values(FocusGroupSummaryReportStatus)),
    summaryReportId: PropTypes.string
};

export default SummaryReportStatusText;
