import { REPORT_PROCESSING_WINDOW_MINUTES } from 'Config';
import ActivityStatus from 'enums/ActivityStatus';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import _ from 'lodash';
import { DateTime, Interval } from 'luxon';

export const FocusGroupSummaryReportStatus = {
    FOCUS_GROUP_INCOMPLETE: 'FOCUS_GROUP_INCOMPLETE',
    NO_REPORT_TASKS: 'NO_REPORT_TASKS',
    ERRONEOUS_SUMMARY_REPORT: 'ERRONEOUS_SUMMARY_REPORT',
    PROCESSING_SUMMARY_REPORT: 'PROCESSING_SUMMARY_REPORT',
    SUMMARY_REPORT_GENERATED_SUCESSFULLY: 'SUMMARY_REPORT_GENERATED_SUCESSFULLY'
};

export const getFocusGroupSummaryReportStatus = (focusGroupStatus, summaryReportStatus) => {
    if (_.camelCase(focusGroupStatus) !== ActivityStatus.completed.enumKey) {
        return FocusGroupSummaryReportStatus.FOCUS_GROUP_INCOMPLETE;
    }

    if (_.camelCase(focusGroupStatus) === ActivityStatus.completed.enumKey && !summaryReportStatus) {
        return FocusGroupSummaryReportStatus.NO_REPORT_TASKS;
    }

    if (summaryReportStatus === AiSummaryReportStatus.ERROR) {
        return FocusGroupSummaryReportStatus.ERRONEOUS_SUMMARY_REPORT;
    }

    if (summaryReportStatus === AiSummaryReportStatus.PROCESSING) {
        return FocusGroupSummaryReportStatus.PROCESSING_SUMMARY_REPORT;
    }

    if (summaryReportStatus === AiSummaryReportStatus.SUCCESS) {
        return FocusGroupSummaryReportStatus.SUMMARY_REPORT_GENERATED_SUCESSFULLY;
    }
};

export const getProjectSummaryReportStatus = (projectReport, reportRequestedDate) => {
    if (reportRequestedDate && projectReport) {
        const requestDateTime = DateTime.fromISO(reportRequestedDate).toUTC();
        const modifiedDateTime = DateTime.fromISO(projectReport.modifiedOn).toUTC();

        if (requestDateTime > modifiedDateTime) {
            return AiSummaryReportStatus.PROCESSING;
        } else {
            return projectReport.reportStatus;
        }
    } else if (projectReport) {
        return projectReport.reportStatus;
    } else if (reportRequestedDate) {
        const requestDateTime = DateTime.fromISO(reportRequestedDate).toUTC();
        const minutesSinceReportRequested = Interval.fromDateTimes(requestDateTime, DateTime.now()).toDuration('minutes').minutes;

        if (minutesSinceReportRequested < REPORT_PROCESSING_WINDOW_MINUTES) {
            return AiSummaryReportStatus.PROCESSING;
        } else {
            return AiSummaryReportStatus.NONE;
        }
    } else {
        return AiSummaryReportStatus.NONE;
    }
};

export const getOrderedFocusGroups = (focusGroups, summaryReports) => {
    if (!focusGroups || !summaryReports) {
        return [];
    }

    const focusGroupsWithErroneousReports = focusGroups.filter(focusGroup => {
        const focusGroupIsComplete = _.camelCase(focusGroup.status) === ActivityStatus.completed.enumKey;
        const summaryReport = summaryReports.find(summaryReport => summaryReport.focusGroupId === focusGroup.id);
        const hasErroneousSummaryReport = summaryReport?.reportStatus === AiSummaryReportStatus.ERROR;

        return focusGroupIsComplete && hasErroneousSummaryReport;
    });

    const completedFocusGroupsWithoutSummaryReport = focusGroups.filter(focusGroup => {
        const focusGroupIsComplete = _.camelCase(focusGroup.status) === ActivityStatus.completed.enumKey;
        const hasNoSummaryReports = !summaryReports.find(summaryReport => summaryReport.focusGroupId === focusGroup.id);

        return focusGroupIsComplete && hasNoSummaryReports;
    });

    const incompleteFocusGroups = focusGroups.filter(focusGroup => {
        const focusGroupIsIncomplete = _.camelCase(focusGroup.status) !== ActivityStatus.completed.enumKey;
        return focusGroupIsIncomplete;
    });

    const groupsWithPendingReports = focusGroups.filter(focusGroup => {
        const focusGroupIsComplete = _.camelCase(focusGroup.status) === ActivityStatus.completed.enumKey;
        const summaryReport = summaryReports.find(summaryReport => summaryReport.focusGroupId === focusGroup.id);
        const hasPendingSummaryReport = summaryReport?.reportStatus === AiSummaryReportStatus.PROCESSING;

        return focusGroupIsComplete && hasPendingSummaryReport;
    });

    const groupsWithSuccessfulReports = focusGroups.filter(focusGroup => {
        const focusGroupIsComplete = _.camelCase(focusGroup.status) === ActivityStatus.completed.enumKey;
        const summaryReport = summaryReports.find(summaryReport => summaryReport.focusGroupId === focusGroup.id);
        const hasSuccessfulSummaryReport = summaryReport?.reportStatus === AiSummaryReportStatus.SUCCESS;

        return focusGroupIsComplete && hasSuccessfulSummaryReport;
    });

    const orderedFocusGroups = [
        ..._.orderBy(focusGroupsWithErroneousReports, 'scheduledStartDateTime', ['desc']),
        ..._.orderBy(groupsWithPendingReports, 'scheduledStartDateTime', ['desc']),
        ..._.orderBy(completedFocusGroupsWithoutSummaryReport, 'scheduledStartDateTime', ['desc']),
        ..._.orderBy(incompleteFocusGroups, 'scheduledStartDateTime'),
        ..._.orderBy(groupsWithSuccessfulReports, 'scheduledStartDateTime', ['desc'])
    ];

    return orderedFocusGroups;
};

export const hasUnfinishedSessionSummaryReports = (focusGroups, summaryReports) => {
    if (!focusGroups || !summaryReports) {
        return true;
    }

    // If there are no reports at all (i.e. no sessions) then assume reports as a whole are
    // unfinished.
    if (focusGroups.length === 0) {
        return true;
    }

    if (focusGroups.length !== summaryReports.length) {
        return true;
    }

    if (summaryReports?.find(x => x.reportStatus !== AiSummaryReportStatus.SUCCESS)) {
        return true;
    }

    return false;
};
