import PropTypes from 'prop-types';
import { Check, Close, HourglassEmptyOutlined, Pause, QuestionMark, Schedule } from '@mui/icons-material';
import { Avatar, styled } from '@mui/material';
import React from 'react';
import { FocusGroupSummaryReportStatus } from '../util';

const getIcon = (focusGroupSummaryReportStatus) => {
    switch (focusGroupSummaryReportStatus) {
        case FocusGroupSummaryReportStatus.SUMMARY_REPORT_GENERATED_SUCESSFULLY: return <Check />;
        case FocusGroupSummaryReportStatus.PROCESSING_SUMMARY_REPORT: return <HourglassEmptyOutlined />;
        case FocusGroupSummaryReportStatus.ERRONEOUS_SUMMARY_REPORT: return <Close />;
        case FocusGroupSummaryReportStatus.FOCUS_GROUP_INCOMPLETE: return <Schedule />;
        case FocusGroupSummaryReportStatus.NO_REPORT_TASKS: return <Pause />;
        default: return <QuestionMark />;
    }
};

const StyledAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => !['focusGroupSummaryReportStatus'].includes(prop)
})(({ theme, focusGroupSummaryReportStatus }) => {
    switch (focusGroupSummaryReportStatus) {
        case FocusGroupSummaryReportStatus.SUMMARY_REPORT_GENERATED_SUCESSFULLY:
            return {
                backgroundColor: theme.palette.success.light,
                color: theme.palette.success.contrastText
            };
        case FocusGroupSummaryReportStatus.PROCESSING_SUMMARY_REPORT:
            return {
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.contrastText
            };
        case FocusGroupSummaryReportStatus.ERRONEOUS_SUMMARY_REPORT:
            return {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.contrastText
            };
        default:
            return {
                backgroundColor: theme.palette.grey[9],
                color: theme.palette.text.primary
            };
    }
});

const SummaryReportStatusAvatar = ({ focusGroupSummaryReportStatus }) => {
    return (
        <StyledAvatar variant="rounded" focusGroupSummaryReportStatus={focusGroupSummaryReportStatus}>
            { getIcon(focusGroupSummaryReportStatus) }
        </StyledAvatar>
    );
};

SummaryReportStatusAvatar.propTypes = {
    focusGroupSummaryReportStatus: PropTypes.oneOf(Object.values(FocusGroupSummaryReportStatus))
};

export default SummaryReportStatusAvatar;
