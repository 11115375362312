import PropTypes from 'prop-types';
import { AutoAwesome, RestartAlt } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.viewReportButton
}));

const GenerateReportAction = ({ focusGroupId, isRetry, onGenerateReportRequestSuccess }) => {
    const { t } = useTranslation();
    const { mutate: generateReports, isLoading: isGeneratingReports } = useMutation(() => {
        return vlqrApi.post(`focusgroups/${focusGroupId}/generateAiSummaryReports`);
    }, {
        onSuccess: (data, variables, context) => {
            onGenerateReportRequestSuccess();
        },
        onError: handleError
    });

    if (isRetry) {
        return (
            <StyledButton startIcon={<RestartAlt />} disabled={isGeneratingReports} onClick={generateReports}>
                { t('common.retry') }
            </StyledButton>
        );
    } else {
        return (
            <StyledButton startIcon={<AutoAwesome />} disabled={isGeneratingReports} onClick={generateReports}>
                { t('aiReports.generateAISummary') }
            </StyledButton>
        );
    }
};

GenerateReportAction.propTypes = {
    focusGroupId: PropTypes.string,
    isRetry: PropTypes.bool,
    onGenerateReportRequestSuccess: PropTypes.func
};

export default GenerateReportAction;
