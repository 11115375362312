import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getProjectFocusGroups = async (projectId) => {
    const { data } = await vlqrApi.get(`projects/${projectId}/focusGroups`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useProjectFocusGroups = (projectId, options = {}) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['projectFocusGroups', projectId], () => getProjectFocusGroups(projectId),
        options
    );

    return { data, refetch, isLoading, isError };
};

export default useProjectFocusGroups;
