import PropTypes from 'prop-types';
import { Divider, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import CompanyRole from 'enums/CompanyRole';
import useCompany from 'api/useCompany';
import ReportTaskErrorDetailsModal from 'components/ReportTaskErrorDetailsModal/ReportTaskErrorDetailsModal';
import { useTranslation } from 'react-i18next';

const ErroneousReportsStatus = ({ summaryReportId }) => {
    const { t } = useTranslation();
    const { companyId } = useOutletContext();
    const [isViewingErrorDetails, setIsViewingErrorDetails] = useState(false);
    const { data: selectedCompany } = useCompany(companyId);
    const companyRole = CompanyRole[selectedCompany?.role];

    return (
        <React.Fragment>
            <Stack direction="row" gap={1}>
                <Typography variant="body1" color="error">
                    { t('common.failedToGenerateReport') }
                </Typography>

                { companyRole === CompanyRole.vlStaff &&
                    <React.Fragment>
                        <Divider flexItem orientation="vertical" />
                        <Link
                            underline='always'
                            component="button"
                            variant="body1"
                            onClick={() => setIsViewingErrorDetails(true)}
                        >
                            { t('common.viewDetails') }
                        </Link>
                    </React.Fragment>
                }
            </Stack>

            <ReportTaskErrorDetailsModal
                summaryReportId={summaryReportId}
                isOpen={isViewingErrorDetails}
                onClose={() => setIsViewingErrorDetails(false)}
            />
        </React.Fragment>
    );
};

ErroneousReportsStatus.propTypes = {
    summaryReportId: PropTypes.string
};

export default ErroneousReportsStatus;
