import PropTypes from 'prop-types';
import LoadingPlaceholder from 'components/shared/LoadingPlaceholder';
import React, { useEffect, useRef, useState } from 'react';
import AiSummaryReportStatus from 'enums/AiSummaryReportStatus';
import { Box, Button, GlobalStyles, Stack, styled, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import useProject from 'api/useProject';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';
import { useTranslation } from 'react-i18next';
import { DeleteForever, Print, RestartAlt } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import useAiSummaryReport from 'api-new/stim-based/useAiSummaryReport';
import ProjectSummaryReportTimespan from './ProjectSummaryReportTimespan';
import { usePrevious } from '@uidotdev/usehooks';

const ContentObscurerGradient = styled(Box)({
    position: 'absolute',
    bottom: 0, left: 0, right: 0,
    height: 100,
    background: 'linear-gradient(180deg, transparent 0, white 100%)',
    '@media print': { display: 'none' }
});

const ProjectSummaryReport = ({ projectId, projectReportId, onReportRegenerated, onReportDeleted }) => {
    const { t } = useTranslation();
    const regenerateReportsFeatureIsEnabled = useFeatureIsOn('regenerate-ai-reports');
    const deleteReportsFeatureIsEnabled = useFeatureIsOn('delete-ai-reports');
    const { data: projectReport, isLoading: isLoadingProjectReport } = useAiSummaryReport(projectReportId);
    const [isShowingAllReportContent, setIsShowingAllReportContent] = useState(false);
    const { data: project, isLoading: isLoadingProject } = useProject(projectId);
    const printRef = useRef();

    const previousProjectReportStatus = usePrevious(projectReport?.reportStatus);
    const currentProjectReportStatus = projectReport?.reportStatus;

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    const { mutate: generateReport, isLoading: isGeneratingReport } = useMutation(() => {
        return vlqrApi.post(`projects/${projectId}/generateAiSummaryReport`);
    }, {
        onSuccess: () => {
            onReportRegenerated();
        },
        onError: (error) => {
            handleError(error);
        }
    });

    const { mutate: deleteReport, isLoading: isDeletingReport } = useMutation(() => {
        return vlqrApi.delete(`aiSummaryReports/${projectReportId}`);
    }, {
        onError: handleError,
        onSuccess: () => {
            onReportDeleted();
        }
    });

    // When report changes to 'success' from 'processing', show expanded view
    useEffect(() => {
        if (previousProjectReportStatus === AiSummaryReportStatus.PROCESSING && currentProjectReportStatus === AiSummaryReportStatus.SUCCESS) {
            setIsShowingAllReportContent(true);
        }
    }, [previousProjectReportStatus, currentProjectReportStatus]);

    if (isLoadingProjectReport || isLoadingProject || !projectReport.content) {
        return <LoadingPlaceholder height={200} />;
    }

    return (
        <Stack
            ref={printRef}
            gap={1}
            sx={{ '@media print': { padding: 0 } }}
        >
            <GlobalStyles styles={{
                // Set default margins for print view
                '@page': {
                    margin: '20mm 15mm 20mm 15mm !important;'
                }
            }}/>

            <Stack direction={'row'} alignItems={'flex-start'}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">
                        { t('projectSummaryReport.reportTitle', { projectName: project.name }) }
                    </Typography>

                    <ProjectSummaryReportTimespan projectReportId={projectReportId} />
                </Box>

                <Stack
                    direction="row"
                    useFlexGap
                    gap={1}
                    sx={{ '@media print': { display: 'none' } }}
                >
                    { deleteReportsFeatureIsEnabled &&
                        <LoadingButton
                            loading={isDeletingReport}
                            loadingPosition="start"
                            startIcon={<DeleteForever />}
                            variant="outlined"
                            color="error"
                            onClick={deleteReport}
                        >
                            { t('common.buttons.delete') }
                        </LoadingButton>
                    }

                    { regenerateReportsFeatureIsEnabled &&
                        <LoadingButton
                            loading={isGeneratingReport}
                            loadingPosition="start"
                            startIcon={<RestartAlt />}
                            variant="outlined"
                            color="newPrimary"
                            onClick={generateReport}
                        >
                            { t('aiSummaryReport.regenerate') }
                        </LoadingButton>
                    }

                    <Button
                        startIcon={<Print />}
                        variant="outlined"
                        color="newPrimary"
                        onClick={handlePrint}
                    >
                        { t('common.buttons.print') }
                    </Button>
                </Stack>
            </Stack>

            <Box
                sx={{
                    maxHeight: isShowingAllReportContent ? 'auto' : 400,
                    overflow: 'hidden',
                    position: 'relative',
                    '@media print': { maxHeight: 'auto' }
                }}
            >
                <Box
                    sx={{
                        '& ul': {
                            paddingInlineEnd: '40px',
                            '& li': { marginBottom: 1 }
                        },
                        '& h2': {
                            fontSize: '1.3em',
                            fontWeight: '500',
                            marginTop: 4,
                            marginBottom: 0,
                            '&:first-of-type': {
                                marginTop: 1
                            }
                        }
                    }}
                >
                    <Markdown>
                        { projectReport.content }
                    </Markdown>
                </Box>

                { !isShowingAllReportContent &&
                    <ContentObscurerGradient />
                }
            </Box>

            <Button
                fullWidth
                onClick={() => setIsShowingAllReportContent(!isShowingAllReportContent)}
                sx={{ '@media print': { display: 'none' } }}
            >
                { isShowingAllReportContent ? 'Show Less' : 'Show More' }
            </Button>
        </Stack>
    );
};

ProjectSummaryReport.propTypes = {
    onReportDeleted: PropTypes.func,
    onReportRegenerated: PropTypes.func,
    projectId: PropTypes.string,
    projectReportId: PropTypes.string,
    reportStatus: PropTypes.oneOf(Object.values(AiSummaryReportStatus))
};

export default ProjectSummaryReport;
