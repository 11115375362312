import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import valImg from './val.png';

const ValAvatar = ({ isProcessing = false }) => {
    return (
        <Box sx={{ borderRadius: '100%', height: 140, width: 140, overflow: 'hidden', position: 'relative' }}>
            <img src={valImg} alt="val" height="100%" width="auto" />

            { isProcessing &&
                <CircularProgress
                    size={150}
                    sx={{
                        animationDuration: '5s',
                        position: 'absolute',
                        opacity: 0.7,
                        top: -5, left: -5, right: 0, bottom: 0
                    }}
                />
            }
        </Box>
    );
};

ValAvatar.propTypes = {
    isProcessing: PropTypes.bool
};

export default ValAvatar;
