import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getAiSummaryReport = async (aiSummaryReportId) => {
    if (!aiSummaryReportId) {
        return null;
    }

    const { data } = await vlqrApi.get(`aiSummaryReports/${aiSummaryReportId}`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReport = (aiSummaryReportId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReport', aiSummaryReportId],
        () => getAiSummaryReport(aiSummaryReportId)
    );

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReport;
