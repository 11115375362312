import { useQuery } from 'react-query';
import vlqrApi, { handleError } from 'utils/vlqrApi';

const getAiSummaryReportTimespan = async (aiSummaryReportId) => {
    if (!aiSummaryReportId) {
        return null;
    }

    const { data } = await vlqrApi.get(`aiSummaryReports/${aiSummaryReportId}/timespan`)
        .catch((error) => {
            handleError(error);
        });

    return data;
};

const useAiSummaryReportTimespan = (aiSummaryReportId) => {
    const { data, refetch, isLoading, isError } = useQuery(
        ['aiSummaryReportTimespan', aiSummaryReportId],
        () => getAiSummaryReportTimespan(aiSummaryReportId)
    );

    return { data, refetch, isLoading, isError };
};

export default useAiSummaryReportTimespan;
