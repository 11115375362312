export const mainConfig = {

};

// Defines the amount of time that must elapse before a session is no
// longer assumed to be 'processing' reports.
//
// This is in place to account for the time it takes for a generate report event
// to be processed in EventGrid at which point a distinct 'AiSummaryReport' task will
// be available from the DB for tracking task progress instead.
export const REPORT_PROCESSING_WINDOW_MINUTES = 5;
